<template>
	<div class="yu-page-wrapper">
		<YHeader :logo="logo" :bgColor="bgColor" spacing>
			<YNavbar :list="navbarList"/>
			<div slot="foot"></div>
		</YHeader>
		<slot></slot>
	</div>
</template>

<script>
	import YHeader from './YHeader.vue'
	import YNavbar from './YNavbar.vue'
	export default {
		name: 'YPage',
		components: {
			YHeader,YNavbar
		},
		props: {
			logo: {
				type: String,
				default () {
					return ''
				}
			},
			bgColor: {
				type: String,
				default () {
					return '#314e98'
				}
			}
		},
		data() {
			return {

			}
		},
	}
</script>
<style scoped>
	.yu-page-wrapper {
		height: 100%;
	}
</style>
