import YPage from '@/components/YPage';
import Vue from 'vue';
// 待注册组件
const registerComponentList = {
	YPage: YPage
}

// 组装好的注册对象
export default {
	install() {
		for (let [itemKey, item] of Object.entries(registerComponentList)) {
			// 组件库：已经有install方法，直接用Vue.use注册;
			if (item.install) {
				Vue.use(item)
			}
			// 自定义组件，使用Vue.component注册
			else {
				Vue.component(itemKey, item)
			}
		}
	}
}