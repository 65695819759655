<template>
	<div class="yu-navbar-wrapper">
		<div class="yu-navbar-list" v-for="(item,index) in list" :key="index">
			<router-link class="name" :to="item.url">{{item.name}}</router-link>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'YNavbar',
		props: {
			list: {
				type: Array,
				default () {
					return []
				}
			},
		},
		data() {
			return {

			}
		},
		watch: {

		},
		methods: {

		}
	}
</script>
<style lang="scss" scoped>
	.yu-navbar-wrapper{
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
    .yu-navbar-list{
      margin: 0 25px;
      .name{
        font-size: 15px;
        line-height: 48px;
        color: #fff;
        cursor: pointer;
      }
    }
	}

  @media (max-width: 576px) {
    .yu-navbar-wrapper{
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      .yu-navbar-list{
        margin: 0 25px;
        .name{
          font-size: 15px;
          line-height: 48px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
</style>
