<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'App',
		data() {
			return {
				
			}
		},
		mounted() {
			
		},
		methods:{
			
		}
	}
</script>

<style lang="scss">
	@import '@/assets/css/public.css';
	@import '@/assets/css/theme.scss';
	/*修改第三方插件hooper的垂直滚动样式*/
	.hooper-pagination.is-vertical {
	  left: 0;
	  right: auto!important;
	  top: 50%;
	  transform: translateY(-50%);
	}
	
	.hooper-pagination.is-vertical .hooper-indicator {
	  width: 3px !important;
	  height: 20px;
	  margin: 3px 0;
	}
	
	.hooper-indicator {
	  background-color: rgba(255, 255, 255, 0.4) !important;
	}
	
	.hooper-indicator:hover, .hooper-indicator.is-active {
	  background-color: #fff !important;
	}
</style>