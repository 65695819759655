// import Vue from 'vue'
import copy from './copy' // 引入指令
import toolTip from './toolTip'
const directives = {
    copy,
    toolTip
}
export default {
    install(Vue) {
        Object.keys(directives).forEach(key => {
            Vue.directive(key, directives[key])
        })
    }
}
