import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {global} from './mixins'
import ComponentList from './global'
import directives from './directives'
// 引入组件库的少量全局样式变量
import 'tdesign-vue/es/style/index.css';

import { MessagePlugin } from 'tdesign-vue';

Vue.config.productionTip = false

Vue.prototype.$message = MessagePlugin;
Vue.use(directives)
Vue.use(ComponentList)
Vue.mixin(global)
new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
