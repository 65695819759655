<template>
  <div class="page-header-wrapper" :style="`height:${spacing?height+'px':'auto'}`">
    <div class="page-header-container" :class="{'page-header-fixed':fixed}" :style="{background:bgColor}">
      <div class="page-header" :style="{width:width + 'px',height:height + 'px'}">
        <div class="page-header-hd" :style="{width:siderWidth + 'px'}">
          <img class="logo" :src="logo" alt="logo" v-if="logo" />
          <slot name="logo" v-else></slot>
          <svg class="menu" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="30" height="30" @click.stop="showNavbar = true">
            <path d="M196.266667 277.333333h635.733333c17.066667 0 34.133333-12.8 34.133333-34.133333s-17.066667-29.866667-38.4-29.866667H196.266667c-21.333333 0-34.133333 17.066667-34.133334 34.133334s12.8 29.866667 34.133334 29.866666zM827.733333 477.866667H196.266667c-17.066667 0-34.133333 12.8-34.133334 34.133333s12.8 34.133333 34.133334 34.133333h635.733333c17.066667 0 34.133333-12.8 34.133333-34.133333s-17.066667-34.133333-38.4-34.133333zM827.733333 746.666667H196.266667c-17.066667 0-34.133333 12.8-34.133334 34.133333s12.8 29.866667 34.133334 29.866667h635.733333c17.066667 0 34.133333-12.8 34.133333-34.133334s-17.066667-29.866667-38.4-29.866666z"></path>
          </svg>
        </div>
        <div class="page-header-bd">
          <img class="logo" :src="logo" alt="logo" v-if="logo" />
          <slot name="logo" v-else></slot>
          <div class="page-header-mask" v-show="showNavbar" @click="handleMask"></div>
          <Transition :name="`header-app-${direction}`" appear>
            <div class="page-header-nav-app" :class="`page-header-nav-app-${direction}`" v-show="showNavbar">
              <div class="nav-app-header" :style="{height:height + 'px'}">
                <div class="app-logo-wrapper">
                  <img class="logo" :src="logo" alt="logo" v-if="logo" />
                  <slot name="logo" v-else></slot>
                </div>
                <svg class="close" v-if="showClose" @click.stop="close" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M512 466.944l233.472-233.472a31.744 31.744 0 0 1 45.056 45.056L557.056 512l233.472 233.472a31.744 31.744 0 0 1-45.056 45.056L512 557.056l-233.472 233.472a31.744 31.744 0 0 1-45.056-45.056L466.944 512 233.472 278.528a31.744 31.744 0 0 1 45.056-45.056z"></path></svg>
              </div>
              <div class="nav-app-body">
                <slot></slot>
              </div>
            </div>
          </Transition>
          <div class="page-header-nav-pc">
            <slot></slot>
          </div>
        </div>
        <div class="page-header-ft" :style="{width:siderWidth + 'px',color:color}">
          <div class="info-wrapper">
            <slot name="info"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    logo: {
      type: String,
      default () {
        return ''
      }
    },
    bgColor: {
      type: String,
      default () {
        return 'transparent'
      }
    },
    color: {
      type: String,
      default () {
        return '#333'
      }
    },
    height: {
      type: [String, Number],
      default () {
        return 60
      }
    },
    width: { //导航有效宽度，仅在大屏生效
      type: [String, Number],
      default () {
        return 1200
      }
    },
    siderWidth: { //左右两侧宽度，仅在小屏幕生效
      type: [String, Number],
      default () {
        return 60
      }
    },
    fixed: { //是否固定在顶部
      type: Boolean,
      default () {
        return true
      }
    },
    spacing: { //是否占用文档流高度
      type: Boolean,
      default () {
        return false
      }
    },
    direction: { //从哪个方向出来 left | right
      type: String,
      default () {
        return 'left'
      }
    },
    showClose: { //是否显示关闭按钮
      type: Boolean,
      default () {
        return true
      }
    },
    maskClose: { //是否可以点击遮罩关闭
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data() {
    return {
      showNavbar: false
    }
  },
  methods: {
    hide() {
      this.showNavbar = false;
    },
    close() {
      this.hide();
      this.$emit('hide');
    },
    handleMask() {
      if(!this.maskClose) return
      this.close();
    }
  }
}
</script>
<style scoped>
.page-header-wrapper .page-header-fixed {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  width: 100%;
}

.page-header-wrapper .page-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 30px;
}

.page-header-wrapper .page-header-hd {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.page-header-wrapper .page-header-bd {
  -webkit-flex: 1;
  -ms-flex: 1;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  flex: 1;
}

@media screen and (min-width: 768px) {
  .page-header-wrapper .page-header-container {
    padding: 0 30px;
  }

  .page-header-wrapper .page-header {
    margin: 0 auto;
  }

  .page-header-wrapper .page-header-hd .logo {
    height: 36px;
    cursor: pointer;
  }

  .page-header-wrapper .page-header-hd .menu {
    display: none;
  }

  .page-header-wrapper .page-header-hd,
  .page-header-wrapper .page-header-ft {
    width: auto !important;
  }

  .page-header-wrapper .page-header-bd .logo {
    display: none;
  }

  .page-header-wrapper .page-header-bd .page-header-nav-app,
  .page-header-wrapper .page-header-bd .page-header-mask{
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .page-header-wrapper .page-header {
    padding: 0 10px;
    gap: 10px;
    width: auto !important;
  }

  .page-header-wrapper .page-header-hd .logo {
    display: none;
  }

  .page-header-wrapper .page-header-hd .menu {
    fill: v-bind(color);
    padding: 6px 5px;
  }

  .page-header-wrapper .page-header-bd {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-header-wrapper .page-header-bd .logo {
    max-height: 36px;
    max-width: 100%;
  }

  .page-header-wrapper .page-header-bd .page-header-mask{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0,0,0,0.5);
  }

  .page-header-wrapper .page-header-bd .page-header-nav-app {
    background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    z-index: 3;
    width: 60%;
    opacity: 1;
    transition: transform 0.2s ease-out;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
  }

  .page-header-wrapper .page-header-bd .page-header-nav-app .nav-app-header{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 10px;
    gap: 10px;
  }

  .page-header-wrapper .page-header-bd .page-header-nav-app .app-logo-wrapper{
    -webkit-flex: 1;
    -ms-flex: 1;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    flex: 1;
    font-size: 0;
    line-height: 0;
    width: 0;
  }

  /* .page-header-wrapper .page-header-bd .page-header-nav-app .app-logo{
    max-height: 36px;
    max-width: 100%;
  } */

  .page-header-wrapper .page-header-bd .page-header-nav-app .close{
    fill: #333;
    padding: 9px 6px;
  }

  .page-header-wrapper .page-header-bd .page-header-nav-app .nav-app-body{
    -webkit-flex: 1;
    -ms-flex: 1;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    flex: 1;
    height: 0;
    overflow-y: auto;
  }

  .page-header-wrapper .page-header-bd .page-header-nav-app-left {
    left: 0;
  }

  .page-header-wrapper .page-header-bd .page-header-nav-app-right {
    right: 0;
  }

  .page-header-wrapper .page-header-bd .page-header-nav-pc {
    display: none;
  }

  .page-header-wrapper .page-header-ft {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .page-header-wrapper .page-header-ft .info-wrapper {
    padding-right: 5px;
  }

  .header-app-left-enter-active,
  .header-app-left-leave-active,
  .header-app-right-enter-active,
  .header-app-right-leave-active {
    transition: transform 0.2s ease-out;
  }

  .header-app-left-enter,
  .header-app-left-leave-active {
    /* 2.1.8以下使用v-leave-to */
    transform: translateX(-100%);
    opacity: 0;
  }

  .header-app-right-enter,
  .header-app-right-leave-active {
    transform: translateX(100%);
    opacity: 0;
  }
}
</style>
