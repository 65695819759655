import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)


let originPush = Router.prototype.push; //备份原push方法

Router.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) { //如果传了回调函数，直接使用
        originPush.call(this, location, resolve, reject);
    } else { //如果没有传回调函数，手动添加
        originPush.call(this, location, () => {
        }, () => {
        });
    }
}

export default new Router({
    mode: "hash",
    routes: [
        {
            path: '/',
            name: 'Index',
            component: () => import('@/views/Index.vue')
        }, {
            path: '/article/index',
            name: 'ArticleIndex',
            component: () => import('@/views/article/Index.vue')
        }, {
            path: '/dorm/index',
            name: 'DormIndex',
            component: () => import('@/views/dorm/Index.vue')
        }, {
            path: '/room/index/shaw/loan',
            name: 'RoomIndexShawLoan',
            component: () => import('@/views/room/Index.vue')
        },
        {
            path: '/markdown/index',
            name: 'MarkdownIndex',
            component: () => import('@/views/markdown/Index.vue')
        },
        {
            path: '/works/index',
            name: 'WorksIndex',
            component: () => import('@/views/works/Index.vue')
        },
        {
            path: '/markdown/detail',
            name: 'MarkdownDetail',
            component: () => import('@/views/markdown/Detail.vue')
        }, {
            path: '/tool/Index',
            name: 'ToolIndex',
            component: () => import('@/views/tool/Index.vue')
        }, {
            path: '/tool/jsonFormatter',
            name: 'ToolJsonFormatter',
            component: () => import('@/views/tool/JsonFormatter.vue')
        }, {
            path: '/tool/image',
            name: 'ToolImage',
            component: () => import('@/views/tool/Image.vue')
        }, {
            path: '/tool/css/triangle',
            name: 'ToolCssTriangle',
            component: () => import('@/views/css/Triangle.vue')
        },{
            path: '/tool/footballLottery',
            name: 'FootballLottery',
            component: () => import('@/views/tool/FootballLottery.vue')
        }, {
            path: '/color/Index',
            name: 'ColorIndex',
            component: () => import('@/views/color/Index.vue')
        }]
})
