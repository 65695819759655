export const global = {
	data() {
		return {
			navbarList:[
				{
					name:'文章',
					url:'/article/index'
				},
				{
					name:'笔记',
					url:'/markdown/index'
				},{
					name:'工具',
					url:'/tool/Index'
				},
				{
					name:'颜色',
					url:'/color/Index'
				},{
					name:'文档',
					url:'/document/Index'
				}
			]
		}
	},
	onLoad() {

	},
	methods: {

	}
}
